import { memoize } from 'lodash-es';
/**
 * Commercial Testing Instrument
 *
 * Returns a map { <bidderName>: true } of bidders
 * according to the pbtest URL parameter
 */
const pbTestNameMap = memoize(() => new URLSearchParams(window.location.search)
    .getAll('pbtest')
    .reduce((acc, value) => {
    acc[value] = true;
    return acc;
}, {}), () => 
// Same implicit parameter as the memoized function
window.location.search);
const queryStringToUrlVars = memoize((queryString) => Array.from(new URLSearchParams(queryString).entries()) // polyfill.io guarantees URLSearchParams
    .reduce((acc, [key, value]) => {
    acc[key] = value === '' ? true : value;
    return acc;
}, {}));
/**
 * returns a map of querystrings
 * eg ?foo=bar&fizz=buzz returns {foo: 'bar', fizz: 'buzz'}
 * ?foo=bar&foo=baz returns {foo: 'baz'}
 * ?foo returns { foo: true }
 */
const getUrlVars = (query) => queryStringToUrlVars(query ?? window.location.search);
export { getUrlVars, pbTestNameMap };
