import { adSizes } from 'core/ad-sizes';
export const priceGranularity = {
    buckets: [
        {
            max: 10,
            increment: 0.01,
        },
        {
            max: 15,
            increment: 0.1,
        },
        {
            max: 100,
            increment: 1,
        },
    ],
};
export const criteoPriceGranularity = {
    buckets: [
        {
            max: 12,
            increment: 0.01,
        },
        {
            max: 20,
            increment: 0.05,
        },
        {
            max: 500,
            increment: 1,
        },
    ],
};
export const ozonePriceGranularity = (width, height) => {
    const sizeString = [width, height].join(',');
    if (sizeString === adSizes.skyscraper.toString() ||
        sizeString === adSizes.halfPage.toString()) {
        return {
            buckets: [
                {
                    max: 10,
                    increment: 0.01,
                },
                {
                    max: 15,
                    increment: 0.1,
                },
                {
                    max: 50,
                    increment: 1,
                },
            ],
        };
    }
    if (sizeString === adSizes.leaderboard.toString() ||
        sizeString === adSizes.billboard.toString() ||
        sizeString === adSizes.mpu.toString() ||
        sizeString === adSizes.outstreamDesktop.toString() ||
        sizeString === adSizes.outstreamMobile.toString()) {
        return {
            buckets: [
                {
                    max: 12,
                    increment: 0.01,
                },
                {
                    max: 20,
                    increment: 0.1,
                },
                {
                    max: 50,
                    increment: 1,
                },
            ],
        };
    }
    return undefined;
};
export const indexPriceGranularity = (width, height) => {
    const sizeString = [width, height].join(',');
    if (sizeString === adSizes.skyscraper.toString() ||
        sizeString === adSizes.halfPage.toString()) {
        return {
            buckets: [
                {
                    max: 10,
                    increment: 0.01,
                },
                {
                    max: 15,
                    increment: 0.05,
                },
                {
                    max: 50,
                    increment: 1,
                },
            ],
        };
    }
    if (sizeString === adSizes.leaderboard.toString() ||
        sizeString === adSizes.billboard.toString() ||
        sizeString === adSizes.mpu.toString()) {
        return {
            buckets: [
                {
                    max: 12,
                    increment: 0.01,
                },
                {
                    max: 20,
                    increment: 0.05,
                },
                {
                    max: 50,
                    increment: 1,
                },
            ],
        };
    }
    return undefined;
};
