import { breakpoints as sourceBreakpoints } from '@guardian/source-foundations';
import { once } from 'lodash-es';
import { EventTimer } from 'core';
import { toGoogleTagSize } from 'lib/utils/googletag-ad-size';
import { getUrlVars } from 'lib/utils/url';
import { initSlotIas } from './init-slot-ias';
const breakpointViewports = {
    mobile: [0, 0],
    phablet: [sourceBreakpoints.phablet, 0],
    tablet: [sourceBreakpoints.tablet, 0],
    desktop: [sourceBreakpoints.desktop, 0],
};
const adUnit = once(() => {
    const urlVars = getUrlVars();
    return urlVars['ad-unit']
        ? `/${window.guardian.config.page.dfpAccountId}/${String(urlVars['ad-unit'])}`
        : window.guardian.config.page.adUnit;
});
const isBreakpoint = (breakpoint) => breakpoint in breakpointViewports;
/**
 * Builds a googletag size mapping based on the breakpoints and ad sizes from
 * the size mapping in commercial-core and the viewport sizes from source-foundations.
 */
const buildGoogletagSizeMapping = (sizeMapping) => {
    const mapping = window.googletag.sizeMapping();
    Object.entries(sizeMapping).forEach(([breakpoint, sizes]) => {
        if (isBreakpoint(breakpoint)) {
            mapping.addSize(breakpointViewports[breakpoint], sizes.map(toGoogleTagSize));
        }
    });
    return mapping.build();
};
const isMultiSize = (size) => {
    return Array.isArray(size) && !!size.length;
};
const isSizeInArray = (size, sizes) => {
    if (size === 'fluid') {
        return sizes.includes('fluid');
    }
    else if (Array.isArray(size)) {
        return !!sizes.find((item) => item[0] === size[0] && item[1] === size[1]);
    }
    return false;
};
/**
 * Take all the sizes in a size mapping and reduce to a single array of sizes, for use in `defineSlot`
 *
 * @todo this is possibly redundant as these are only used if a size mapping is not defined which we always provide.
 * @param sizeMapping googletag size mapping
 * @returns all the sizes that were present in the size mapping
 */
const collectSizes = (sizeMapping) => {
    const sizes = [];
    // as we're using sizeMapping, pull out all the ad sizes, as an array of arrays
    sizeMapping?.forEach(([, sizesForBreakpoint]) => {
        if (isMultiSize(sizesForBreakpoint)) {
            sizesForBreakpoint.forEach((size) => {
                if (!isSizeInArray(size, sizes)) {
                    sizes.push(size);
                }
            });
        }
    });
    return sizes;
};
const isEligibleForOutstream = (slotTarget) => typeof slotTarget === 'string' &&
    (slotTarget === 'inline1' || slotTarget === 'top-above-nav');
const allowSafeFrameToExpand = (slot) => {
    slot.setSafeFrameConfig({
        allowOverlayExpansion: false,
        allowPushExpansion: true,
        sandbox: true,
    });
    return slot;
};
const defineSlot = (adSlotNode, sizeMapping, slotTargeting = {}) => {
    const slotTarget = adSlotNode.getAttribute('data-name');
    EventTimer.get().mark('defineSlotStart', slotTarget);
    const id = adSlotNode.id;
    const googletagSizeMapping = buildGoogletagSizeMapping(sizeMapping);
    if (!googletagSizeMapping) {
        throw new Error(`Could not define slot for ${id}. A googletag size mapping could not be created.`);
    }
    const sizes = collectSizes(googletagSizeMapping);
    let slot;
    if (adSlotNode.getAttribute('data-out-of-page')) {
        slot = window.googletag.defineOutOfPageSlot(adUnit(), id);
        slot?.defineSizeMapping(googletagSizeMapping);
    }
    else {
        slot = window.googletag.defineSlot(adUnit(), sizes, id);
        slot?.defineSizeMapping(googletagSizeMapping);
        if (slot && isEligibleForOutstream(slotTarget)) {
            allowSafeFrameToExpand(slot);
        }
    }
    if (!slot) {
        throw new Error(`Could not define slot for ${id}`);
    }
    const slotReady = initSlotIas(id, slot);
    void slotReady.then(() => {
        EventTimer.get().mark('defineSlotEnd', slotTarget);
        EventTimer.get().mark('slotReady', slotTarget);
    });
    const isbn = window.guardian.config.page.isbn;
    if (slotTarget === 'im' && isbn) {
        slot.setTargeting('isbn', isbn);
    }
    const fabricKeyValues = new Map([
        ['top-above-nav', 'fabric1'],
        ['merchandising-high', 'fabric2'],
        ['merchandising', 'fabric3'],
    ]);
    const slotFabric = fabricKeyValues.get(slotTarget);
    if (slotFabric) {
        slot.setTargeting('slot-fabric', slotFabric);
    }
    Object.entries(slotTargeting).forEach(([key, value]) => {
        slot?.setTargeting(key, value);
    });
    slot.addService(window.googletag.pubads())
        .setTargeting('slot', slotTarget)
        .setTargeting('testgroup', String(Math.floor(100 * Math.random())));
    return {
        slot,
        slotReady,
    };
};
export { buildGoogletagSizeMapping, collectSizes, defineSlot };
