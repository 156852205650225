import { getCookie } from '@guardian/libs';
const timeInDaysFromNow = (daysFromNow) => {
    const tmpDate = new Date();
    tmpDate.setDate(tmpDate.getDate() + daysFromNow);
    return tmpDate.getTime().toString();
};
const cookieIsExpiredOrMissing = (cookieName) => {
    const expiryDateFromCookie = getCookie({ name: cookieName });
    if (!expiryDateFromCookie)
        return true;
    const expiryTime = parseInt(expiryDateFromCookie, 10);
    const timeNow = new Date().getTime();
    return timeNow >= expiryTime;
};
export { timeInDaysFromNow, cookieIsExpiredOrMissing };
