import { isString } from '@guardian/libs';
import { trackNativeAdLinkClick } from '../analytics/google';
const sendClick = (adSlot, linkName) => {
    trackNativeAdLinkClick(adSlot.id, linkName);
};
const init = (register) => {
    register('click', (linkName, ret, iframe) => sendClick(iframe?.closest('.js-ad-slot') ?? {
        id: 'unknown',
    }, isString(linkName) ? linkName : ''));
};
export { init };
