import { isString } from '@guardian/libs';
/* -- Types -- */
const videoLengths = [
    '25',
    '30',
    '60',
    '90',
    '120',
    '150',
    '180',
    '210',
    '240',
    '270',
    '300',
];
/* -- Methods -- */
const getVideoLength = (videoLength) => {
    const index = Math.min(Math.ceil(videoLength / 30), 10);
    return videoLengths[index] ?? null;
};
const getUrlKeywords = (url) => {
    const lastSegment = url
        .split('/')
        .filter(Boolean) // This handles a trailing slash
        .slice(-1)[0];
    return isString(lastSegment) ? lastSegment.split('-').filter(Boolean) : [];
};
const concatUnique = (a, b) => [
    ...new Set([...a, ...b]),
];
// "0" means content < 2 hours old
// "1" means content between 2 hours and 24 hours old.
// "2" means content between 24 hours and 3 days old
// "3" means content between 3 and 7 days old
// "4" means content between 7 days and 1 month old
// "5" means content between 1 and 10 months old
// "6" means content between 10 and 14 months old
// "7" means content more than 14 months old
const calculateRecentlyPublishedBucket = (webPublicationDate) => {
    const now = Date.now();
    const hoursSincePublication = (now - webPublicationDate) / 1000 / 60 / 60;
    const daysSincePublication = hoursSincePublication / 24;
    const monthsSincePublication = daysSincePublication / 30; // near enough for our purposes
    if (hoursSincePublication < 2)
        return '0';
    if (hoursSincePublication < 24)
        return '1';
    if (daysSincePublication < 3)
        return '2';
    if (daysSincePublication < 7)
        return '3';
    if (daysSincePublication < 30)
        return '4';
    if (monthsSincePublication < 10)
        return '5';
    if (monthsSincePublication < 14)
        return '6';
    return '7';
};
const getContentTargeting = ({ eligibleForDCR, path, renderingPlatform, section, sensitive, videoLength, webPublicationDate, keywords, }) => {
    const urlkw = getUrlKeywords(path);
    return {
        dcre: eligibleForDCR ? 't' : 'f',
        rc: calculateRecentlyPublishedBucket(webPublicationDate),
        rp: renderingPlatform,
        s: section,
        sens: sensitive ? 't' : 'f',
        urlkw,
        vl: videoLength ? getVideoLength(videoLength) : null,
        allkw: concatUnique(urlkw, keywords),
    };
};
export { getContentTargeting };
