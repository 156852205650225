import { log } from '@guardian/libs';
import { buildAppNexusTargeting, buildAppNexusTargetingObject, } from 'lib/build-page-targeting';
import { dfpEnv } from 'lib/dfp/dfp-env';
import { isInAuOrNz, isInUk, isInUsa, isInUsOrCa } from 'lib/utils/geo-utils';
import { pbTestNameMap } from 'lib/utils/url';
import { containsBillboard, containsBillboardNotLeaderboard, containsDmpu, containsLeaderboard, containsLeaderboardOrBillboard, containsMobileSticky, containsMpu, containsMpuOrDmpu, getBreakpointKey, shouldIncludeAdYouLike, shouldIncludeAppNexus, shouldIncludeCriteo, shouldIncludeImproveDigital, shouldIncludeImproveDigitalSkin, shouldIncludeKargo, shouldIncludeOpenx, shouldIncludeSmart, shouldIncludeSonobi, shouldIncludeTripleLift, shouldIncludeTrustX, shouldIncludeXaxis, shouldUseOzoneAdaptor, stripDfpAdPrefixFrom, stripMobileSuffix, } from '../utils';
import { getAppNexusDirectBidParams } from './appnexus';
import { getImprovePlacementId, getImproveSizeParam, getImproveSkinPlacementId, } from './improve-digital';
const isArticle = window.guardian.config.page.contentType === 'Article';
const isDesktopAndArticle = getBreakpointKey() === 'D' && isArticle;
const getTrustXAdUnitId = (slotId, isDesktopArticle) => {
    switch (stripMobileSuffix(slotId)) {
        case 'dfp-ad--inline1':
            return '2960';
        case 'dfp-ad--inline2':
            if (isDesktopArticle)
                return '3826';
            return '3827';
        case 'dfp-ad--inline3':
            if (isDesktopArticle)
                return '3828';
            return '3829';
        case 'dfp-ad--inline4':
            if (isDesktopArticle)
                return '3830';
            return '3831';
        case 'dfp-ad--inline5':
            if (isDesktopArticle)
                return '3832';
            return '3833';
        case 'dfp-ad--inline6':
            if (isDesktopArticle)
                return '3834';
            return '3835';
        case 'dfp-ad--inline7':
            if (isDesktopArticle)
                return '3836';
            return '3837';
        case 'dfp-ad--inline8':
            if (isDesktopArticle)
                return '3838';
            return '3839';
        case 'dfp-ad--inline9':
            if (isDesktopArticle)
                return '3840';
            return '3841';
        case 'dfp-ad--mostpop':
            return '2961';
        case 'dfp-ad--right':
            return '2962';
        case 'dfp-ad--top-above-nav':
            return '2963';
        case 'dfp-ad--comments':
            return '3840';
        case 'dfp-ad--mobile-sticky':
            return '8519';
        default:
            // for inline10 and onwards just use same IDs as inline9
            if (slotId.startsWith('dfp-ad--inline')) {
                if (isDesktopArticle)
                    return '3840';
                return '3841';
            }
            log('commercial', `PREBID: Failed to get TrustX ad unit for slot ${slotId}.`);
            return '';
    }
};
const getIndexSiteId = () => {
    const site = window.guardian.config.page.pbIndexSites.find((s) => s.bp === getBreakpointKey());
    return site?.id ? site.id.toString() : '';
};
const getXaxisPlacementId = (sizes) => {
    switch (getBreakpointKey()) {
        case 'D':
            if (containsMpuOrDmpu(sizes)) {
                return 20943665;
            }
            if (containsLeaderboardOrBillboard(sizes)) {
                return 20943666;
            }
            return 20943668;
        case 'M':
            if (containsMpuOrDmpu(sizes)) {
                return 20943669;
            }
            return 20943670;
        case 'T':
            if (containsMpuOrDmpu(sizes)) {
                return 20943671;
            }
            if (containsLeaderboardOrBillboard(sizes)) {
                return 20943672;
            }
            return 20943674;
        default:
            return -1;
    }
};
const getTripleLiftInventoryCode = (slotId, sizes) => {
    if (containsLeaderboard(sizes)) {
        if (isInUsOrCa()) {
            return 'theguardian_topbanner_728x90_prebid';
        }
        else if (isInAuOrNz()) {
            return 'theguardian_topbanner_728x90_prebid_AU';
        }
    }
    if (containsMpu(sizes)) {
        if (isInUsOrCa()) {
            return isArticle
                ? 'theguardian_article_300x250_prebid'
                : 'theguardian_sectionfront_300x250_prebid';
        }
        else if (isInAuOrNz()) {
            return isArticle
                ? 'theguardian_article_300x250_prebid_AU'
                : 'theguardian_sectionfront_300x250_prebid_AU';
        }
    }
    if (containsMobileSticky(sizes)) {
        if (isInUsOrCa()) {
            return 'theguardian_320x50_HDX';
        }
        else if (isInAuOrNz()) {
            return 'theguardian_320x50_HDX_AU';
        }
    }
    return '';
};
// Is pbtest being used?
const isPbTestOn = () => Object.keys(pbTestNameMap()).length > 0;
// Helper for conditions
const inPbTestOr = (liveClause) => isPbTestOn() || liveClause;
/* Bidders */
const appNexusBidder = (pageTargeting) => ({
    name: 'and',
    switchName: 'prebidAppnexus',
    bidParams: (slotId, sizes) => getAppNexusDirectBidParams(sizes, pageTargeting),
});
const openxClientSideBidder = (pageTargeting) => ({
    name: 'oxd',
    switchName: 'prebidOpenx',
    bidParams: () => {
        if (isInUsOrCa()) {
            return {
                delDomain: 'guardian-us-d.openx.net',
                unit: '540279544',
                customParams: buildAppNexusTargetingObject(pageTargeting),
            };
        }
        if (isInAuOrNz()) {
            return {
                delDomain: 'guardian-aus-d.openx.net',
                unit: '540279542',
                customParams: buildAppNexusTargetingObject(pageTargeting),
            };
        }
        // UK and ROW
        return {
            delDomain: 'guardian-d.openx.net',
            unit: '540279541',
            customParams: buildAppNexusTargetingObject(pageTargeting),
        };
    },
});
const getOzonePlacementId = (sizes) => {
    if (isInUsa()) {
        if (getBreakpointKey() === 'D') {
            if (containsBillboard(sizes)) {
                return '3500010912';
            }
            if (containsMpu(sizes)) {
                return '3500010911';
            }
        }
        return '1420436308';
    }
    return '0420420500';
};
const ozoneClientSideBidder = (pageTargeting) => ({
    name: 'ozone',
    switchName: 'prebidOzone',
    bidParams: (_slotId, sizes) => {
        const advert = dfpEnv.adverts.get(_slotId);
        const testgroup = advert?.testgroup
            ? { testgroup: advert.testgroup }
            : {};
        return {
            publisherId: 'OZONEGMG0001',
            siteId: '4204204209',
            placementId: getOzonePlacementId(sizes),
            customData: [
                {
                    settings: {},
                    targeting: {
                        // Assigns a random integer between 0 and 99
                        ...testgroup,
                        ...buildAppNexusTargetingObject(pageTargeting),
                    },
                },
            ],
            ozoneData: {}, // TODO: confirm if we need to send any
        };
    },
});
const sonobiBidder = (pageTargeting) => ({
    name: 'sonobi',
    switchName: 'prebidSonobi',
    bidParams: (slotId) => ({
        ad_unit: window.guardian.config.page.adUnit,
        dom_id: slotId,
        appNexusTargeting: buildAppNexusTargeting(pageTargeting),
        pageViewId: window.guardian.ophan.pageViewId,
    }),
});
const getPubmaticPublisherId = () => {
    if (isInUsOrCa()) {
        return '157206';
    }
    if (isInAuOrNz()) {
        return '157203';
    }
    return '157207';
};
const getKargoPlacementId = (sizes) => {
    if (getBreakpointKey() === 'D') {
        // top-above-nav on desktop, fronts-banners in the future
        if (containsLeaderboardOrBillboard(sizes)) {
            return '_yflg9S7c2x';
        }
        // right hand slots on desktop, aka right, inline2+ or mostpop
        if (containsMpu(sizes) && containsDmpu(sizes)) {
            return '_zOpeEAyfiz';
        }
        // other MPUs on desktop (inline1)
        return '_qDBbBXYtzA';
    }
    // mobile-sticky on mobile
    if (containsMobileSticky(sizes)) {
        return '_odszPLn2hK';
    }
    // MPUs on mobile aka top-above-nav, inline on mobile and tablet
    return '_y9LINEsbfh';
};
const pubmaticBidder = (slotSizes) => {
    const defaultParams = {
        name: 'pubmatic',
        switchName: 'prebidPubmatic',
        bidParams: (slotId) => ({
            publisherId: getPubmaticPublisherId(),
            adSlot: stripDfpAdPrefixFrom(slotId),
        }),
    };
    // The only prebid compatible size for fronts-banner-ads and the merchandising-high is the billboard (970x250)
    // This check is to distinguish from the top-above-nav which, includes a leaderboard
    if (containsBillboardNotLeaderboard(slotSizes)) {
        return {
            ...defaultParams,
            bidParams: (slotId) => ({
                ...defaultParams.bidParams(slotId),
                placementId: 'theguardian_970x250_only',
            }),
        };
    }
    return defaultParams;
};
const trustXBidder = {
    name: 'trustx',
    switchName: 'prebidTrustx',
    bidParams: (slotId) => ({
        uid: getTrustXAdUnitId(slotId, isDesktopAndArticle),
    }),
};
const tripleLiftBidder = {
    name: 'triplelift',
    switchName: 'prebidTriplelift',
    bidParams: (slotId, sizes) => ({
        inventoryCode: getTripleLiftInventoryCode(slotId, sizes),
    }),
};
const improveDigitalBidder = {
    name: 'improvedigital',
    switchName: 'prebidImproveDigital',
    bidParams: (slotId, sizes) => ({
        publisherId: 995,
        placementId: getImprovePlacementId(sizes),
        size: getImproveSizeParam(slotId, isDesktopAndArticle),
    }),
};
const improveDigitalSkinBidder = {
    name: 'improvedigital',
    switchName: 'prebidImproveDigitalSkins',
    bidParams: () => ({
        placementId: getImproveSkinPlacementId(),
        size: {},
    }),
};
const xaxisBidder = {
    name: 'xhb',
    switchName: 'prebidXaxis',
    bidParams: (slotId, sizes) => ({
        placementId: getXaxisPlacementId(sizes),
    }),
};
const adYouLikeBidder = {
    name: 'adyoulike',
    switchName: 'prebidAdYouLike',
    bidParams: () => {
        if (isInUk()) {
            return {
                placement: '2b4d757e0ec349583ce704699f1467dd',
            };
        }
        if (isInUsOrCa()) {
            return {
                placement: '7fdf0cd05e1d4bf39a2d3df9c61b3495',
            };
        }
        if (isInAuOrNz()) {
            return {
                placement: '5cf05e1705a2d57ba5d51e03f2af9208',
            };
        }
        // ROW
        return {
            placement: 'c1853ee8bfe0d4e935cbf2db9bb76a8b',
        };
    },
};
const criteoBidder = (slotSizes) => {
    const defaultParams = {
        name: 'criteo',
        switchName: 'prebidCriteo',
    };
    // The only prebid compatible size for fronts-banner-ads and the merchandising-high is the billboard (970x250)
    // This check is to distinguish from the top-above-nav slot, which includes a leaderboard
    if (containsBillboardNotLeaderboard(slotSizes)) {
        return {
            ...defaultParams,
            bidParams: () => ({
                zoneId: 1759354,
            }),
        };
    }
    return {
        ...defaultParams,
        bidParams: () => ({
            networkId: 337,
        }),
    };
};
const smartBidder = {
    name: 'smartadserver',
    switchName: 'prebidSmart',
    bidParams: () => ({
        siteId: 465656,
        pageId: 1472549,
        formatId: 105870,
    }),
};
const kargoBidder = {
    name: 'kargo',
    switchName: 'prebidKargo',
    bidParams: (_slotId, sizes) => ({
        placementId: getKargoPlacementId(sizes),
    }),
};
// There's an IX bidder for every size that the slot can take
const indexExchangeBidders = (slotSizes) => {
    let indexSiteId = getIndexSiteId();
    // The only prebid compatible size for fronts-banner-ads and the merchandising-high is the billboard (970x250)
    // This check is to distinguish from the top-above-nav slot, which includes a leaderboard
    if (containsBillboardNotLeaderboard(slotSizes)) {
        indexSiteId = '983842';
    }
    return slotSizes.map((size) => ({
        name: 'ix',
        switchName: 'prebidIndexExchange',
        bidParams: () => ({
            siteId: indexSiteId,
            size,
        }),
    }));
};
const biddersBeingTested = (allBidders) => allBidders.filter((bidder) => pbTestNameMap()[bidder.name]);
const biddersSwitchedOn = (allBidders) => {
    const isSwitchedOn = (bidder) => window.guardian.config.switches[bidder.switchName] ?? false;
    return allBidders.filter((bidder) => isSwitchedOn(bidder));
};
const currentBidders = (slotSizes, pageTargeting) => {
    const biddersToCheck = [
        [shouldIncludeCriteo(), criteoBidder(slotSizes)],
        [shouldIncludeSmart(), smartBidder],
        [shouldIncludeSonobi(), sonobiBidder(pageTargeting)],
        [shouldIncludeTrustX(), trustXBidder],
        [shouldIncludeTripleLift(), tripleLiftBidder],
        [shouldIncludeAppNexus(), appNexusBidder(pageTargeting)],
        [shouldIncludeImproveDigital(), improveDigitalBidder],
        [shouldIncludeImproveDigitalSkin(), improveDigitalSkinBidder],
        [shouldIncludeXaxis(), xaxisBidder],
        [true, pubmaticBidder(slotSizes)],
        [shouldIncludeAdYouLike(slotSizes), adYouLikeBidder],
        [shouldUseOzoneAdaptor(), ozoneClientSideBidder(pageTargeting)],
        [shouldIncludeOpenx(), openxClientSideBidder(pageTargeting)],
        [shouldIncludeKargo(), kargoBidder],
    ];
    const otherBidders = biddersToCheck
        .filter(([shouldInclude]) => inPbTestOr(shouldInclude))
        .map(([, bidder]) => bidder);
    const allBidders = indexExchangeBidders(slotSizes).concat(otherBidders);
    return isPbTestOn()
        ? biddersBeingTested(allBidders)
        : biddersSwitchedOn(allBidders);
};
export const bids = (slotId, slotSizes, pageTargeting) => currentBidders(slotSizes, pageTargeting).map((bidder) => ({
    bidder: bidder.name,
    params: bidder.bidParams(slotId, slotSizes),
}));
export const _ = {
    getIndexSiteId,
    getXaxisPlacementId,
    getTrustXAdUnitId,
    indexExchangeBidders,
};
