import fastdom from 'fastdom';
import { dfpEnv } from './dfp-env';
const removeFromDfpEnv = (advert) => {
    dfpEnv.adverts.delete(advert.id);
    dfpEnv.advertsToLoad = dfpEnv.advertsToLoad.filter((_) => _ !== advert);
};
const removeAd = (advert) => {
    const parent = advert.node.parentElement;
    if (parent?.classList.contains('ad-slot-container')) {
        parent.remove();
    }
    else {
        advert.node.remove();
    }
};
const emptyAdvert = (advert) => {
    fastdom.mutate(() => {
        window.googletag.destroySlots([advert.slot]);
        removeAd(advert);
        removeFromDfpEnv(advert);
    });
};
export { emptyAdvert };
