import { isBoolean } from '@guardian/libs';
import fastdom from 'fastdom';
const fullWidth = (fullWidth, slotContainer) => fastdom.mutate(() => {
    if (fullWidth) {
        slotContainer.classList.add('ad-slot--full-width');
    }
    else {
        slotContainer.classList.remove('ad-slot--full-width');
    }
});
const init = (register) => {
    register('full-width', (specs, ret, iframe) => {
        if (iframe && specs) {
            if (!isBoolean(specs)) {
                return;
            }
            const adSlot = iframe.closest('.js-ad-slot') ?? undefined;
            // only allow for banner ads
            const name = adSlot?.dataset.name;
            if (!name?.startsWith('fronts-banner') || !adSlot) {
                return;
            }
            const slotContainer = iframe.closest('.ad-slot-container') ?? undefined;
            if (!slotContainer) {
                return;
            }
            return fullWidth(specs, slotContainer);
        }
    });
};
export const _ = { fullWidth };
export { init };
