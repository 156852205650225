/**
 * IAS script filters bad ads
 * https://integralads.com/uk/
 * @param  {} {shouldRun}
 */
export const ias = {
    shouldRun: true,
    url: '//cdn.adsafeprotected.com/iasPET.1.js',
    name: 'ias',
};
