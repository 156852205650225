/**
 * Provides a shorthand wrapper over vanilla DOM manipulation
 */
class $$ {
    /**
     * An array of the matched elements
     */
    #elements;
    constructor(query, context) {
        this.#elements = Array.from((context ?? document).querySelectorAll(query));
    }
    get(index) {
        if (typeof index === 'number') {
            return this.#elements[index];
        }
        return this.#elements;
    }
    // https://github.com/LeaVerou/bliss/blob/master/bliss.shy.js#L705
    /**
     * Set attributes on all matched elements.
     */
    setAttributes(attrs) {
        Object.entries(attrs).forEach(([prop, val]) => this.#elements.forEach((element) => element.setAttribute(prop, val)));
        return this;
    }
    // https://github.com/LeaVerou/bliss/blob/master/bliss.shy.js#L692
    /**
     * Set CSS properties on all matched elements.
     */
    css(styles) {
        Object.entries(styles).forEach(([prop, val]) => {
            this.#elements.forEach((element) => element.style.setProperty(prop, val));
        });
        return this;
    }
    /**
     * Removes all matched elements from the DOM.
     */
    remove() {
        this.#elements.forEach((element) => element.parentNode?.removeChild(element));
    }
}
const _$$ = (query, context) => new $$(query, context);
export { _$$ as $$ };
