// total_hours_spent_maintaining_this = 81.5
import { log } from '@guardian/libs';
import { memoize } from 'lodash-es';
import fastdom from 'lib/fastdom-promise';
import { amIUsed } from 'lib/utils/am-i-used';
import { init as initSpacefinderDebugger } from './spacefinder-debug-tools';
const query = (selector, context) => [
    ...(context ?? document).querySelectorAll(selector),
];
/** maximum time (in ms) to wait for images to be loaded */
const LOADING_TIMEOUT = 5_000;
const defaultOptions = {
    waitForImages: true,
    waitForInteractives: false,
    pass: 'inline1',
};
const isIframe = (node) => node instanceof HTMLIFrameElement;
const isIframeLoaded = (iframe) => {
    try {
        return iframe.contentWindow?.document.readyState === 'complete';
    }
    catch (err) {
        // TODO remove try / catch if an error is never thrown
        amIUsed('spacefinder.ts', 'isIframeLoaded');
        return true;
    }
};
const getFuncId = (rules) => rules.bodySelector || 'document';
const isImage = (element) => element instanceof HTMLImageElement;
const onImagesLoaded = memoize((rules) => {
    const notLoaded = query('img', rules.body)
        .filter(isImage)
        .filter((img) => !img.complete && img.loading !== 'lazy');
    const imgPromises = notLoaded.map((img) => new Promise((resolve) => {
        img.addEventListener('load', resolve);
    }));
    return Promise.all(imgPromises).then(() => Promise.resolve());
}, getFuncId);
const waitForSetHeightMessage = (iframe, callback) => {
    window.addEventListener('message', (event) => {
        if (event.source !== iframe.contentWindow)
            return;
        try {
            const message = JSON.parse(event.data);
            if (message.type === 'set-height' && Number(message.value) > 0) {
                callback();
            }
        }
        catch (ex) {
            log('commercial', 'Unparsable message sent from iframe', ex);
        }
    });
};
const onInteractivesLoaded = memoize(async (rules) => {
    const notLoaded = query('.element-interactive', rules.body).filter((interactive) => {
        const iframes = Array.from(interactive.children).filter(isIframe);
        return !(iframes[0] && isIframeLoaded(iframes[0]));
    });
    if (notLoaded.length === 0 || !('MutationObserver' in window)) {
        return Promise.resolve();
    }
    const mutations = notLoaded.map((interactive) => new Promise((resolve) => {
        // Listen for when iframes are added as children to interactives
        new MutationObserver((records, instance) => {
            if (!records[0]?.addedNodes[0] ||
                !isIframe(records[0]?.addedNodes[0])) {
                return;
            }
            const iframe = records[0].addedNodes[0];
            // Listen for when the iframes are resized
            // This is a sign they have fully loaded and spacefinder can proceed
            waitForSetHeightMessage(iframe, () => {
                instance.disconnect();
                resolve();
            });
        }).observe(interactive, {
            childList: true,
        });
    }));
    await Promise.all(mutations);
}, getFuncId);
const partitionCandidates = (list, filterElement) => {
    const filtered = [];
    const exclusions = [];
    list.forEach((element) => {
        if (filterElement(element, filtered[filtered.length - 1])) {
            filtered.push(element);
        }
        else {
            exclusions.push(element);
        }
    });
    return [filtered, exclusions];
};
// test one element vs another for the given rules
const testCandidate = (rule, candidate, opponent) => {
    const isMinAbove = candidate.top - opponent.bottom >= rule.minAbove;
    const isMinBelow = opponent.top - candidate.top >= rule.minBelow;
    const pass = isMinAbove || isMinBelow;
    if (!pass) {
        // if the test fails, add debug information to the candidate metadata
        const isBelow = candidate.top < opponent.top;
        const required = isBelow ? rule.minBelow : rule.minAbove;
        const actual = isBelow
            ? opponent.top - candidate.top
            : candidate.top - opponent.bottom;
        candidate.meta?.tooClose.push({
            required,
            actual,
            element: opponent.element,
        });
    }
    return pass;
};
// test one element vs an array of other elements for the given rule
const testCandidates = (rule, candidate, opponents) => opponents
    .map((opponent) => testCandidate(rule, candidate, opponent))
    .every(Boolean);
const enforceRules = (measurements, rules, spacefinderExclusions) => {
    let candidates = measurements.candidates;
    // enforce absoluteMinAbove rule
    let [filtered, exclusions] = partitionCandidates(candidates, (candidate) => !rules.absoluteMinAbove ||
        candidate.top + measurements.bodyTop >= rules.absoluteMinAbove);
    spacefinderExclusions.absoluteMinAbove = exclusions;
    candidates = filtered;
    // enforce minAbove and minBelow rules
    [filtered, exclusions] = partitionCandidates(candidates, (candidate) => {
        const farEnoughFromTopOfBody = candidate.top >= rules.minAbove;
        const farEnoughFromBottomOfBody = candidate.top + rules.minBelow <= measurements.bodyHeight;
        return farEnoughFromTopOfBody && farEnoughFromBottomOfBody;
    });
    spacefinderExclusions.aboveAndBelow = exclusions;
    candidates = filtered;
    // enforce content meta rule
    const { clearContentMeta } = rules;
    if (clearContentMeta) {
        [filtered, exclusions] = partitionCandidates(candidates, (candidate) => !!measurements.contentMeta &&
            candidate.top >
                measurements.contentMeta.bottom + clearContentMeta);
        spacefinderExclusions.contentMeta = exclusions;
        candidates = filtered;
    }
    // enforce selector rules
    if (rules.selectors) {
        const selectorExclusions = [];
        for (const [selector, rule] of Object.entries(rules.selectors)) {
            [filtered, exclusions] = partitionCandidates(candidates, (candidate) => testCandidates(rule, candidate, measurements.opponents?.[selector] ?? []));
            spacefinderExclusions[selector] = exclusions;
            selectorExclusions.push(...exclusions);
        }
        candidates = candidates.filter((candidate) => !selectorExclusions.includes(candidate));
    }
    if (rules.filter) {
        [filtered, exclusions] = partitionCandidates(candidates, rules.filter);
        spacefinderExclusions.custom = exclusions;
        candidates = filtered;
    }
    return candidates;
};
class SpaceError extends Error {
    constructor(rules) {
        super();
        this.name = 'SpaceError';
        this.message = `There is no space left matching rules from ${rules.bodySelector}`;
    }
}
/**
 * Wait for the page to be ready (images loaded, interactives loaded)
 * or for LOADING_TIMEOUT to elapse, whichever comes first.
 * @param  {SpacefinderRules} rules
 * @param  {SpacefinderOptions} options
 */
const getReady = (rules, options) => Promise.race([
    new Promise((resolve) => window.setTimeout(() => resolve('timeout'), LOADING_TIMEOUT)),
    Promise.all([
        options.waitForImages ? onImagesLoaded(rules) : Promise.resolve(),
        options.waitForInteractives
            ? onInteractivesLoaded(rules)
            : Promise.resolve(),
    ]),
]).then((value) => {
    if (value === 'timeout') {
        log('commercial', 'Spacefinder timeout hit');
    }
});
const getCandidates = (rules, spacefinderExclusions) => {
    let candidates = query(rules.bodySelector + rules.slotSelector);
    if (rules.fromBottom) {
        candidates.reverse();
    }
    if (rules.startAt) {
        let drop = true;
        const [filtered, exclusions] = partitionCandidates(candidates, (candidate) => {
            if (candidate === rules.startAt) {
                drop = false;
            }
            return !drop;
        });
        spacefinderExclusions.startAt = exclusions;
        candidates = filtered;
    }
    if (rules.stopAt) {
        let keep = true;
        const [filtered, exclusions] = partitionCandidates(candidates, (candidate) => {
            if (candidate === rules.stopAt) {
                keep = false;
            }
            return keep;
        });
        spacefinderExclusions.stopAt = exclusions;
        candidates = filtered;
    }
    return candidates;
};
const getDimensions = (element) => Object.freeze({
    top: element.offsetTop,
    bottom: element.offsetTop + element.offsetHeight,
    element,
    meta: {
        tooClose: [],
    },
});
const getMeasurements = (rules, candidates) => {
    const contentMeta = rules.clearContentMeta
        ? document.querySelector('.js-content-meta') ?? undefined
        : undefined;
    const opponents = rules.selectors
        ? Object.keys(rules.selectors).map((selector) => [selector, query(rules.bodySelector + selector)])
        : [];
    return fastdom.measure(() => {
        let bodyDistanceToTopOfPage = 0;
        let bodyHeight = 0;
        if (rules.body instanceof Element) {
            const bodyElement = rules.body.getBoundingClientRect();
            // bodyElement is relative to the viewport, so we need to add scroll position to get the distance
            bodyDistanceToTopOfPage = bodyElement.top + window.scrollY;
            bodyHeight = bodyElement.height;
        }
        const candidatesWithDims = candidates.map(getDimensions);
        const contentMetaWithDims = rules.clearContentMeta && contentMeta
            ? getDimensions(contentMeta)
            : undefined;
        const opponentsWithDims = opponents.reduce((result, [selector, selectedElements]) => {
            result[selector] = selectedElements.map(getDimensions);
            return result;
        }, {});
        return {
            bodyTop: bodyDistanceToTopOfPage,
            bodyHeight,
            candidates: candidatesWithDims,
            contentMeta: contentMetaWithDims,
            opponents: opponentsWithDims,
        };
    });
};
// Rather than calling this directly, use spaceFiller to inject content into the page.
// SpaceFiller will safely queue up all the various asynchronous DOM actions to avoid any race conditions.
const findSpace = async (rules, options, exclusions = {}) => {
    options = { ...defaultOptions, ...options };
    rules.body =
        (rules.bodySelector &&
            document.querySelector(rules.bodySelector)) ||
            document;
    await getReady(rules, options);
    const candidates = getCandidates(rules, exclusions);
    const measurements = await getMeasurements(rules, candidates);
    const winners = enforceRules(measurements, rules, exclusions);
    initSpacefinderDebugger(exclusions, winners, rules, options.pass);
    // TODO Is this really an error condition?
    if (!winners.length) {
        throw new SpaceError(rules);
    }
    return winners.map((candidate) => candidate.element);
};
export { findSpace, SpaceError };
